import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import PersonClass from 'models/PersonClass';

export const listPerson = async(name?: string): Promise<Response<PersonClass[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get('/manager/person', {
      params: {
        name,
      }
    })

    return Response.fromData(
      response.data.map((item) => PersonClass.fromJson(item)),
      response.status,
    )
  } catch (e: any) {
    return Response.error(e);
  }
};
