import React from 'react';
import { ClassTitle, Divider, Container } from './styles';

interface IClassAndDividerProps {
  title: string;
}

const ClassAndDivider: React.FC<IClassAndDividerProps> = ({ title }) => {
  return (
    <Container>
      <Divider />
      <ClassTitle>{title}</ClassTitle>
    </Container>
  );
};

export default ClassAndDivider;
