import styled from 'styled-components';
import { Accordion } from '@campus/components';

export const Lists = styled.div`
  flex: 1;
`;

export const Divider = styled.hr`
  height: 1px;
  width: 100%;
  opacity: 0.1;
  background: #fff;
  margin: 0;
`;

export const AccordionStyled = styled(Accordion)`
  margin-bottom: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
`;

export const DivGrades = styled.div`
  margin-bottom: 10px;
`;
