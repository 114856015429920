import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import ManagerClass from 'models/ManagerClass';
import ManagerGradeBySegment, {
  IManagerGradeBySegment
} from 'models/ManagerGradeClassBySegment';
import qs from 'qs';

export const listManagerClasses = async (
  id: string
): Promise<Response<ManagerClass[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/manager/${id}/class`);

    return Response.fromData(
      response.data.map((item) => ManagerClass.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const listManagerGradeClasses = async (
  classesIds?: string[]
): Promise<Response<IManagerGradeBySegment[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/manager/class/detail`, {
      params: {
        classId: classesIds
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    });

    return Response.fromData(
      ManagerGradeBySegment.fromManagerGradeClassModel(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.fromAxiosError(e);
  }
};
