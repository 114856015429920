import { ProgressBar } from '@campus/components';
import styled from 'styled-components';

export const ProgressBarGroup = styled(ProgressBar)`
  width: 100%;
  height: 12px;
`;

export const ProgressBarItemContainer = styled(ProgressBar)<{
  $color?: string;
}>`
  height: 100%;
  && {
    background-color: ${(props) => props.$color};
  }
  border-radius: 0;
`;
