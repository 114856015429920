import styled from 'styled-components';

export const TeacherItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  cursor: pointer;
`;

export const TeacherItemName = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #4f4f4f;
  font-style: normal;
  line-height: 12px;
  margin-top: 5px;
`;

export const TeacherItemDisciplines = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #4f4f4f;
  font-style: normal;
  display: flex;
  align-items: center;
`;

export const TeacherItemDiscipline = styled.div`
  color: #bdbdbd;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;

  &:not(:last-child) {
    margin-right: 2px;
  }
`;
