class PersonClass {
  id?: string;
  name?: string;
  imageUrl?: string;
  personType?: string;
  occupation?: string;

  static fromJson(data: any): PersonClass {
    const personClass = new PersonClass();

    personClass.id = data.Id;
    personClass.name = data?.Name;
    personClass.imageUrl = data?.ImageUrl;
    personClass.personType = data?.PersonType;
    personClass.occupation = data?.Occupation;

    return personClass;
  }
}

export default PersonClass;
