import { Overlay, Tooltip } from '@campus/components';
import React from 'react';
import { ProgressBarItemContainer } from './styles';

interface IProgressBarItem {
  label?: string;
  now: number;
  $color: string;
  showTooltip?: boolean;
}

const ProgressBarItem: React.FC<IProgressBarItem> = ({
  $color,
  label,
  now,
  showTooltip = true,
  ...props
}) => {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  return (
    <>
      <ProgressBarItemContainer
        {...props}
        ref={target}
        $color={$color}
        visuallyHidden={false}
        now={now}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      {showTooltip && (
        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip {...props}>
              {label} - {now}
            </Tooltip>
          )}
        </Overlay>
      )}
    </>
  );
};

export default ProgressBarItem;
