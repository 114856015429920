import React from 'react';
import { useRedirect } from '@campus/auth';
import { capitalizeFirstLetter } from '@campus/commons';
import { Avatar, CampusIcon, Overlay, Tooltip } from '@campus/components';

import { TeacherDiscipline } from 'models/ManagerGradeClass';
import {
  TeacherItemName,
  TeacherItemContainer,
  TeacherItemDiscipline,
  TeacherItemDisciplines
} from './styles';

interface ITeacherItem {
  image: string;
  name: string;
  disciplines: TeacherDiscipline[];
  fullName: string;
  teacherId: string;
}

const TeacherItem: React.FC<ITeacherItem> = ({
  name,
  disciplines,
  image,
  fullName,
  teacherId
}) => {
  const { openTeacher } = useRedirect();
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  const initialDisciplines = disciplines
    .slice(0, 2)
    .map((discipline) => capitalizeFirstLetter(discipline.Name.slice(0, 3)));
  const lastDisciplines = disciplines.slice(2);

  return (
    <TeacherItemContainer onClick={() => openTeacher(teacherId)}>
      <Avatar name={fullName} size={36} image={image} />
      <TeacherItemName>{capitalizeFirstLetter(name)}</TeacherItemName>
      <TeacherItemDisciplines>
        <TeacherItemDiscipline>
          {initialDisciplines.join(' | ')}
        </TeacherItemDiscipline>
        {lastDisciplines.length > 0 && (
          <>
            <div
              className="d-flex align-items-center"
              ref={target}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <CampusIcon
                name="plusCircleGrey"
                color="#808080"
                size={12}
                holderStyle={{ display: 'flex' }}
              />
            </div>
            <Overlay target={target.current} show={show} placement="right">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  {lastDisciplines
                    .map((discipline) => capitalizeFirstLetter(discipline.Name))
                    .join(' | ')}
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
      </TeacherItemDisciplines>
    </TeacherItemContainer>
  );
};

export default TeacherItem;
