import { OverlayTrigger, Tooltip } from '@campus/components';
import React from 'react';
import {
  TrilhaStatLineContainer,
  TrilhaStatLineLabel,
  TrilhaStatLineBarContainer,
  TrilhaStatLineBar,
  TrilhaStatLineCount
} from './styles';

interface ITrilhaStatLine {
  label: string;
  count: number;
  percentage: number;
  color: string;
}

const TrilhaStatLine: React.FC<ITrilhaStatLine> = ({
  label,
  count,
  percentage,
  color
}) => (
  <TrilhaStatLineContainer>
    <OverlayTrigger overlay={<Tooltip>{label}</Tooltip>}>
      <TrilhaStatLineLabel>{label}</TrilhaStatLineLabel>
    </OverlayTrigger>
    <TrilhaStatLineBarContainer>
      <TrilhaStatLineBar $color={color} now={percentage} />
    </TrilhaStatLineBarContainer>
    <TrilhaStatLineCount>{count}</TrilhaStatLineCount>
  </TrilhaStatLineContainer>
);

export default TrilhaStatLine;
