import styled from 'styled-components';

export const TrilhaStatLineContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TrilhaStatLineLabel = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  color: #828282;
  display: flex;
  justify-content: end;
  text-align: end;
`;

export const TrilhaStatLineBarContainer = styled.div`
  flex: 2;
  margin: 0 8px;
  width: 100px;
  max-width: 100px;
`;

export const TrilhaStatLineBar = styled.div<{ $color: string; now: number }>`
  width: ${(props) => props.now}%;
  background: ${(props) => props.$color};
  height: 10px;
  mix-blend-mode: normal;
  border-radius: 2px;
`;

export const TrilhaStatLineCount = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #828282;
  width: 26px;
`;
