import styled from 'styled-components';

export const CustomAccordionContainer = styled.div<{
  $lastItem: boolean;
  $isLastItemCollapsed: boolean;
}>`
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.6;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  height: 40px;
  border-top: rgba(255, 255, 255, 0.1) 1px solid;

  ${({ $lastItem, $isLastItemCollapsed }) =>
    $lastItem &&
    !$isLastItemCollapsed &&
    'border-bottom: rgba(255, 255, 255, 0.1) 1px solid;'}

  & :first-child {
    margin: 8px;
  }

  strong {
    margin-left: 3px;
    font-weight: 700;
  }
`;

export const DivIcon = styled.div`
  width: 19px;
`;
