import React from 'react';
import { useDebounce } from 'use-debounce';
import { CampusIcon, Content, Row, Col, SelectAsync } from '@campus/components';
import { listPerson } from 'services/PersonService';
import SearchPersonOptions from './SearchPersonOptions';

import { PlaceholderContainer } from './styles';

const SearchPerson: React.FC = () => {
  const [loadPerson] = useDebounce(async (value) => {
    const persons = await listPerson(value);

    return persons?.data?.map((item) => ({
      value: item,
      label: item.name
    }));
  }, 300);

  const renderPlaceHolder = () => {
    return (
      <PlaceholderContainer>
        <CampusIcon
          name="searchInput"
          holderStyle={{ display: 'flex', marginRight: '5px' }}
        />
        <span>Busque por Estudantes ou Professores</span>
      </PlaceholderContainer>
    );
  };

  return (
    <Content>
      <Row>
        <Col xs={12}>
          <SelectAsync
            id="search-people"
            name="search-people"
            placeholder={renderPlaceHolder()}
            customPlaceholderColor="rgba(0, 0, 0, 0.36)"
            isClearable={true}
            loadOptions={loadPerson}
            components={{
              Option: SearchPersonOptions
            }}
            loadingMessage={() => 'Buscando...'}
            noOptionsMessage={() => 'Sem resultados'}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default SearchPerson;
