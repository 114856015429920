import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { SideMenu, Accordion } from '@campus/components';
import { MenuHeader } from '@campus/commons';

import { PedagogicalPlanningLink } from '@campus/pedagogical-planning';

import { listManagerGradeClasses } from 'services/managerClass';
import { IManagerGradeBySegment } from 'models/ManagerGradeClassBySegment';
import CustomAccordion from './components/CustomAccordion';

import { AccordionStyled, DivGrades, Lists } from './styles';

const Menu: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  const [loading, setLoading] = useState(true);
  const [gradesBySegment, setGradesBySegment] = useState<
    IManagerGradeBySegment[]
  >([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await listManagerGradeClasses();

      if (result.data) {
        setGradesBySegment(result.data);
      }

      setLoading(false);
    };

    getData();
  }, []);

  return (
    <SideMenu {...props}>
      <MenuHeader />
      <Link to="/">
        <SideMenu.Option icon="home">Página inicial</SideMenu.Option>
      </Link>
      <Lists>
        {loading ? (
          <SideMenu.List title="">
            <SideMenu.ListItemSkeleton />
            <SideMenu.ListItemSkeleton />
            <SideMenu.ListItemSkeleton />
          </SideMenu.List>
        ) : (
          gradesBySegment.map((item, i) =>
            item.managerGrades.map((grade, j) => (
              <AccordionStyled
                key={grade.Id}
                defaultActiveKey={gradesBySegment[0].managerGrades[0].Id}
              >
                <CustomAccordion
                  eventKey={grade.Id}
                  lastItem={
                    gradesBySegment.length - 1 === i &&
                    item.managerGrades.length - 1 === j
                  }
                  firstItem={i === 0 && j === 0}
                >
                  {item.segment.toUpperCase()} | <strong>{grade.Name.toUpperCase()}</strong>
                </CustomAccordion>
                <Accordion.Collapse eventKey={grade.Id}>
                  <DivGrades>
                    {grade.Classes.map((classItem) => (
                      <Link key={classItem.Id} to={`/class/${classItem.Id}`}>
                        <SideMenu.ListItem title={classItem.Name} />
                      </Link>
                    ))}
                  </DivGrades>
                </Accordion.Collapse>
              </AccordionStyled>
            ))
          )
        )}

        <PedagogicalPlanningLink />
      </Lists>
    </SideMenu>
  );
};

export default Menu;
