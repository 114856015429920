import styled from 'styled-components';
import { Button } from '@campus/components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledTitle = styled.h6`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.gray700};
`;

export const StyledSubTitle = styled.p`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.gray300};
`;

export const AvatarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 12px;

  h6,
  p {
    margin-bottom: 0px;
  }
`;

export const Content = styled.div<{ $ml?: number }>`
  display: flex;
  flex-direction: row;
  ${(props) => props.$ml && `margin-left: ${props.$ml}px;`}
`;

export const StyledButton = styled(Button)`
  display: flex;
  height: 28px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 3px;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
