import React from 'react';

import {
  Dropdown,
  OverlayTrigger,
  SelectContent,
  SelectContentItem,
  Tooltip
} from '@campus/components';
import ArrowSelectIcon from '@campus/components/assets/icons/arrow-select.svg';
import { dowloadFile } from '@campus/commons';
import { ManagerEvaluationType } from 'models/ManagerGradeClass';

import { SpinnerStyled } from './styles';

interface IDowloadActionProps {
  classes: string[];
  evaluationTypes: ManagerEvaluationType[];
}

const DowloadAction: React.FC<IDowloadActionProps> = ({
  classes,
  evaluationTypes
}) => {
  const [loading, setLoading] = React.useState(false);

  const dowloadNotas = (evaluationTypeId: string) => {
    setLoading(true);
    return dowloadFile('respostas', `report/score`, {
      classId: classes,
      evaluationTypeId: evaluationTypeId
    });
  };

  const dowloadMDC = async () => {
    setLoading(true);
    await dowloadFile('MDC', `report/evaluation`, { classId: classes });
    setLoading(false);
  };

  const dowloadMDCSimplified = async () => {
    setLoading(true);
    await dowloadFile('MDC-simplificado', `report/summary`, {
      classId: classes
    });
    setLoading(false);
  };

  const dowloadActivities = async (evaluationTypeId: string) => {
    setLoading(true);

    await dowloadNotas(evaluationTypeId);

    setLoading(false);
  };

  type CustomToggleProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    value: string;
    disabled: boolean;
  };

  const CustomToggle = React.forwardRef<HTMLInputElement, CustomToggleProps>(
    ({ children, onClick, disabled }, ref) => (
      <SelectContentItem
        $width={loading ? 164 : 150}
        ref={ref}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
      >
        {children}
      </SelectContentItem>
    )
  );

  return (
    <OverlayTrigger
      overlay={
        classes.length === 0 ? (
          <Tooltip id="tooltip-disabled">
            É necessário selecionar pelo menos uma turma
          </Tooltip>
        ) : (
          <div />
        )
      }
    >
      <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          disabled={classes.length === 0 || loading}
        >
          <SelectContent>
            {loading && (
              <SpinnerStyled animation="border" variant="primary" size="sm" />
            )}{' '}
            Baixar Planilhas
            <img src={ArrowSelectIcon} alt="" />
          </SelectContent>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={dowloadMDC}>
            Planilha do MDC completa
          </Dropdown.Item>

          <Dropdown.Item onClick={dowloadMDCSimplified}>
            Planilha do MDC simplificada
          </Dropdown.Item>

          {evaluationTypes.map((evaluationType) => (
            <Dropdown.Item onClick={() => dowloadActivities(evaluationType.Id)}>
              Planilha de Atividades {evaluationType.PluralName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger>
  );
};

export default DowloadAction;
