import React from 'react';
import { components } from 'react-select';

import { useWindowDimensions } from '@campus/commons';
import { Avatar, Button } from '@campus/components';
import { useRedirect } from '@campus/auth';

import {
  AvatarContent,
  Container,
  Content,
  StyledButton,
  StyledTitle,
  StyledSubTitle,
  TitleContent
} from './styles';
import { useNavigate } from 'react-router-dom';

const SearchPersonOptions = (props) => {
  const navigate = useNavigate();
  const { openStudent, openTeacher } = useRedirect();
  const { isMobile } = useWindowDimensions();

  const handleClick = () => {
    switch (props.value.personType) {
      case 'Teacher':
        openTeacher(props.value.id);
        break;
      default:
        openStudent(props.value.id);
        break;
    }
  };

  const getSubTitle = () =>
    `${props.value.personType === 'Teacher' ? 'Professor' : 'Estudante'} ${
      props.value.occupation
    }`;

  return (
    <components.Option {...props}>
      <Container>
        <AvatarContent>
          <Button onClick={handleClick} variant="link" style={{ padding: 6 }}>
            <Avatar
              image={props.value.imageUrl}
              name={`Avatar de ${props.label}`}
              size={35}
            />
          </Button>
        </AvatarContent>
        <TitleContent>
          <StyledTitle>{props.label}</StyledTitle>
          <StyledSubTitle>{getSubTitle()}</StyledSubTitle>
        </TitleContent>
        <Content>
          <StyledButton onClick={handleClick}>
            Acessar{isMobile ? '' : ' Perfil'}
          </StyledButton>
        </Content>
        {props.value.personType === 'Student' && (
          <Content $ml={10}>
            <StyledButton onClick={() => navigate(`student/${props.value.id}`)}>
              Indicadores
            </StyledButton>
          </Content>
        )}
      </Container>
    </components.Option>
  );
};

export default SearchPersonOptions;
