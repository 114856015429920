import { ManagerGradeClassModel } from './ManagerGradeClass';

export interface IManagerGradeBySegment {
  segment?: string;
  managerGrades?: ManagerGradeClassModel[];
}

export default class ManagerGradeBySegment {
  public static fromManagerGradeClassModel(
    managerGradeClass: ManagerGradeClassModel[]
  ): IManagerGradeBySegment[] {
    const managerGradeBySegment: IManagerGradeBySegment[] = [];

    managerGradeClass.forEach((managerGradeClass) => {
      const segment = managerGradeClass.Segment.Name;

      let disciplines = managerGradeClass.Disciplines;

      managerGradeClass.Disciplines = disciplines.sort(
        (a, b) => b.ContentCount - a.ContentCount
      );

      const maxContentCount = disciplines[0].ContentCount;

      managerGradeClass.Disciplines = disciplines.map((discipline) => ({
        ...discipline,
        ContentCountPercentage:
          (discipline.ContentCount / maxContentCount) * 100
      }));

      if (!managerGradeBySegment.find((item) => item.segment === segment)) {
        managerGradeBySegment.push({
          segment,
          managerGrades: []
        });
      }

      const managerGrade = managerGradeBySegment.find(
        (item) => item.segment === segment
      );

      managerGrade.managerGrades.push(managerGradeClass);
    });

    return managerGradeBySegment;
  }
}
