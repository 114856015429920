import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 20px 0;
`;

export const Divider = styled.hr<{ color?: string }>`
  border-top: 1px solid ${(p) => (p.color == null ? '#ccc' : p.color)};
  width: 100%;
  opacity: 1;
  margin: 0;
`;

export const ClassTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  color: #4f4f4f;
  position: absolute;
  top: -14px;
  left: 30px;
  background-color: #f3f3f3;
  padding: 0 10px;
`;
