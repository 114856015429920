import StyledCheck from '@campus/components/dist/Check';
import styled from 'styled-components';
import { Content } from '@campus/components';

export const Root = styled.div`
  overflow: auto;
  height: 100%;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 34px;

  color: #2f80ed;
`;

export const Turmas = styled.div`
  min-width: 224px;
  padding: 16px;
`;

export const TurmasList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TurmasItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const TurmasItemAction = styled.div`
  background: #ebebeb;
  border-radius: 92px;
  width: 20px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TurmaName = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  color: #808080;
`;

export const CheckBox = styled(StyledCheck)``;

export const Teachers = styled.div`
  padding: 16px;
  width: 100%;
`;

export const Trilhas = styled.div`
  min-width: 283px;
  padding: 16px;
`;

export const TitleElementCard = styled.div`
  color: #999999;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px;

  span {
    color: #666666;
  }
`;

export const Tag = styled.div`
  background: #ff4170;
  border-radius: 2px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 0px 4px;
  margin: 0 4px;
  width: 50px;
  height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StudentsStats = styled.div`
  margin: 16px 0 8px 0;
  display: flex;
  flex-direction: column;
`;

export const StatLine = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: #b3b3b3;

  display: flex;

  span {
    color: #2f80ed;
    margin-left: 8px;
  }
`;

export const GenericText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #b3b3b3;
`;

export const TurmasFooter = styled.div`
  margin-top: 8px;
`;

export const ProgressBarContent = styled.div`
  width: 100%;
  padding: 5px 0;
  position: relative;
`;

export const StatsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Percentage = styled.div`
  font-size: 12px;
  color: #828282;
  font-weight: 600;
  margin-left: 8px;
`;

export const TrilhaStats = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TeachersList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 70px);
  grid-gap: 1rem;
  justify-content: space-between;
`;

export const Line = styled.hr`
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  width: 100%;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 0px;
`;

export const ContentStyled = styled(Content)`
  padding-bottom: 0px;
`;
