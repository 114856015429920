import React, { useState } from 'react';

import { CampusIcon, useAccordionButton } from '@campus/components';

import { CustomAccordionContainer } from './styles';

interface ICustomToggle {
  eventKey: string;
  lastItem?: boolean;
  firstItem?: boolean;
}

const CustomAccordion: React.FC<ICustomToggle> = ({
  children,
  eventKey,
  lastItem,
  firstItem
}) => {
  const [isLastItemCollapsed, setIsLastItemCollapsed] = useState(
    firstItem && lastItem
  );
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIsLastItemCollapsed((isLastItemCollapsed) => !isLastItemCollapsed);
  });

  return (
    <CustomAccordionContainer
      onClick={decoratedOnClick}
      $lastItem={lastItem}
      $isLastItemCollapsed={isLastItemCollapsed}
      className="accordion-container"
      role="button"
    >
      <CampusIcon
        name="chevronDown"
        size={7}
        holderStyle={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: 14,
          marginBottom: 14
        }}
        marginIcon="6px"
      />
      {children}
    </CustomAccordionContainer>
  );
};

export default CustomAccordion;
