import React from 'react';
import {
  CampusIcon,
  Card,
  Check,
  OverlayTrigger,
  Tooltip
} from '@campus/components';

import {
  Turmas,
  Teachers,
  Trilhas,
  TitleElementCard,
  TurmasList,
  TurmasItem,
  Tag,
  TurmasItemAction,
  TurmaName,
  StudentsStats,
  StatLine,
  GenericText,
  TurmasFooter,
  ProgressBarContent,
  Percentage,
  StatsRow,
  TeachersList
} from './styles';

import {
  ClassAndDivider,
  TrilhaStatLine,
  VerticalDivider,
  TeacherItem,
  ProgressBarItem,
  DownloadPlanilhas
} from 'components/components';
import { Divider } from 'components/ClassAndDivider/styles';
import { ProgressBarGroup } from 'components/ProgressBarItem/styles';
import { capitalizeFirstLetter, generateColor } from '@campus/commons';
import { ManagerGradeClassModel } from 'models/ManagerGradeClass';
import { useNavigate } from 'react-router-dom';

interface IClassCardProps {
  grade: ManagerGradeClassModel;
}

const ClassCard: React.FC<IClassCardProps> = ({ grade }) => {
  const navigate = useNavigate();
  const [classes, setClasses] = React.useState<string[]>([]);

  const addClass = (id: string) => {
    setClasses([...classes, id]);
  };

  const removeClass = (id: string) => {
    setClasses(classes.filter((c) => c !== id));
  };

  const handleAddClass = (id: string) => {
    if (classes.includes(id)) {
      removeClass(id);
    } else {
      addClass(id);
    }
  };

  return (
    <div key={grade.Id}>
      <ClassAndDivider title={grade.Name} />
      <Card className="flex-row">
        <Turmas>
          <TitleElementCard>
            TURMAS <span>{grade.Classes.length}</span>
          </TitleElementCard>
          <TurmasList>
            {grade.Classes.map((classItem) => (
              <TurmasItem key={classItem.Id}>
                <Check
                  name={classItem.Id}
                  defaultChecked={classes.includes(classItem.Id)}
                  onClick={() => handleAddClass(classItem.Id)}
                />
                <OverlayTrigger overlay={<Tooltip>{classItem.Name}</Tooltip>}>
                  <Tag>{classItem.Name}</Tag>
                </OverlayTrigger>
                <TurmaName>
                  {classItem.StudentCount} aluno
                  {classItem.StudentCount !== 1 ? 's' : ''}
                </TurmaName>
                <TurmasItemAction
                  onClick={() => navigate(`class/${classItem.Id}`)}
                >
                  <CampusIcon
                    name="miniArrowRight"
                    color="#B3B3B3"
                    size={7}
                    marginIcon="0px 0px 3px 1px"
                  />
                </TurmasItemAction>
              </TurmasItem>
            ))}
          </TurmasList>

          <StudentsStats>
            <TitleElementCard>
              ALUNOS <span>{grade.StudentCount}</span>
            </TitleElementCard>
            <StatLine>
              Tempo de Acesso/Dia: <span>{grade.AccessPerDay}min</span>
            </StatLine>
            <StatLine>
              Visualizações Média: <span>{grade.AverageViewCount}</span>
            </StatLine>
          </StudentsStats>

          <Divider />

          <TurmasFooter>
            <GenericText>Acessaram nas Últimas 24h</GenericText>
            <StatsRow className="mb-2">
              <ProgressBarContent>
                <ProgressBarGroup>
                  <ProgressBarItem
                    $color={'#2F80ED'}
                    showTooltip={false}
                    now={grade.StudentAccessPercentage}
                  />
                </ProgressBarGroup>
              </ProgressBarContent>
              <Percentage>{grade.StudentAccessPercentage}%</Percentage>
            </StatsRow>
            <DownloadPlanilhas
              classes={classes}
              evaluationTypes={grade.EvaluationTypes}
            />
          </TurmasFooter>
        </Turmas>

        <VerticalDivider className="my-3" />

        <Teachers>
          <TitleElementCard>
            PROFESSORES <span>{grade.Teachers.length}</span>
          </TitleElementCard>
          <TeachersList>
            {grade.Teachers.map((teacher) => (
              <TeacherItem
                key={teacher.Id}
                name={teacher.Name.split(' ')[0]}
                disciplines={teacher.Disciplines}
                image={teacher.ImageUrl}
                fullName={teacher.Name}
                teacherId={teacher.Id}
              />
            ))}
          </TeachersList>
        </Teachers>

        <VerticalDivider className="my-3" />

        <Trilhas>
          <TitleElementCard>
            TRILHAS <span>{grade.ContentCount}</span>
          </TitleElementCard>
          <GenericText>Trilhas por Tipo:</GenericText>
          <ProgressBarContent>
            <ProgressBarGroup>
              {grade.EvaluationTypes.map((evaluationType) => (
                <ProgressBarItem
                  key={evaluationType.Id}
                  label={evaluationType.Name}
                  $color={evaluationType.EvaluationTypeColor}
                  now={evaluationType.ContentCount}
                />
              ))}
            </ProgressBarGroup>
          </ProgressBarContent>
          <Divider className="my-3" />
          <GenericText className="mb-1">
            Trilhas por Componente Curricular
          </GenericText>
          {grade.Disciplines.map((discipline) => (
            <>
              <TrilhaStatLine
                key={discipline.Id}
                color={generateColor(discipline.Name)}
                count={discipline.ContentCount}
                label={capitalizeFirstLetter(discipline.Name)}
                percentage={discipline.ContentCountPercentage}
              />
              <Divider style={{ opacity: 0.5 }} />
            </>
          ))}
        </Trilhas>
      </Card>
    </div>
  );
};

export default ClassCard;
